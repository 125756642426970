type Environment = 'local' | 'dev' | 'staging' | 'prod';

type GetEnvUrlsReturn = {
  videoUrl: string;
  publicApiUrl: string;
  embedApiUrl: string;
};

const devVideoUrl = 'https://d2rxhdlm2q91uk.cloudfront.net';
const stagingVideoUrl = 'https://d1ix11aj5kfygl.cloudfront.net';
const prodVideoUrl = 'https://d157jlwnudd93d.cloudfront.net';

const devPublicApiUrl = 'https://bshyfw4h5a.execute-api.ap-southeast-2.amazonaws.com/dev';
const stagingPublicApiUrl = 'https://gyzw7rpbq3.execute-api.ap-southeast-2.amazonaws.com/staging';
const prodPublicApiUrl = 'https://vfcjuim1l3.execute-api.ap-southeast-2.amazonaws.com/prod';

const localEmbedApiUrl = 'http://localhost:6060/v2';
const devEmbedApiUrl = 'https://embed-dev.vouchfor.com/v2';
const stagingEmbedApiUrl = 'https://embed-staging.vouchfor.com/v2';
const prodEmbedApiUrl = 'https://embed.vouchfor.com/v2';

// We are handling the case where env is an unknown string so the ts error is a lie
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function getEnvUrls(env: Environment): GetEnvUrlsReturn {
  if (!['local', 'dev', 'staging', 'prod'].includes(env)) {
    throw new Error(`Unknown environment: ${env}`);
  }

  if (env === 'local') {
    return {
      videoUrl: devVideoUrl,
      publicApiUrl: devPublicApiUrl,
      embedApiUrl: localEmbedApiUrl
    };
  }

  if (env === 'dev') {
    return {
      videoUrl: devVideoUrl,
      publicApiUrl: devPublicApiUrl,
      embedApiUrl: devEmbedApiUrl
    };
  }

  if (env === 'staging') {
    return {
      videoUrl: stagingVideoUrl,
      publicApiUrl: stagingPublicApiUrl,
      embedApiUrl: stagingEmbedApiUrl
    };
  }

  if (env === 'prod') {
    return {
      videoUrl: prodVideoUrl,
      publicApiUrl: prodPublicApiUrl,
      embedApiUrl: prodEmbedApiUrl
    };
  }
}

export { devEmbedApiUrl, stagingEmbedApiUrl, prodEmbedApiUrl, getEnvUrls };
export type { Environment };
