import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { v4 as uuidv4 } from 'uuid';

import type { PlayerEmbedProps } from '../PlayerEmbed';

import '../PlayerEmbed';
import './DialogPortal';

type DialogEmbedProps = Omit<PlayerEmbedProps, 'data'> & {
  disableAutoplay?: boolean;
};

@customElement('vouch-embed-dialog')
class DialogEmbed extends LitElement {
  static styles = [
    css`
      :host {
        --vu-button-padding: 10px 20px;
        --vu-button-background: #287179;
        --vu-button-background-hover: #4faab2;

        display: flex;
        width: fit-content;
        height: fit-content;
      }
    `
  ];

  @property({ type: String }) vouchId: DialogEmbedProps['vouchId'];
  @property({ type: String }) templateId: DialogEmbedProps['templateId'];
  @property({ type: Array }) questions: DialogEmbedProps['questions'];

  @property({ type: String }) env: DialogEmbedProps['env'] = 'prod';
  @property({ type: String }) apiKey: DialogEmbedProps['apiKey'] = '';
  @property({ type: Boolean }) disableTracking: DialogEmbedProps['disableTracking'] = false;
  @property({ type: String }) trackingSource: DialogEmbedProps['trackingSource'] = 'embedded_player';

  @property({ type: Array }) controls: DialogEmbedProps['controls'];
  @property({ type: String }) preload: DialogEmbedProps['preload'] = 'none';
  @property({ type: Boolean }) disableAutoplay: DialogEmbedProps['disableAutoplay'] = false;
  @property({ type: Number }) aspectRatio: DialogEmbedProps['aspectRatio'] = 0;

  private _id = uuidv4();

  private _handleRootClick = () => {
    this.dispatchEvent(new CustomEvent('dialogembed:click', { detail: this._id, bubbles: true, composed: true }));
  };

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this._handleRootClick);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this._handleRootClick);
  }

  render() {
    return html`
      <slot>
        <vmp-button size="large">Play</vmp-button>
      </slot>
      <vouch-embed-dialog-portal
        id=${this._id}
        ?autoplay=${false}
        vouchId=${ifDefined(this.vouchId)}
        templateId=${ifDefined(this.templateId)}
        .questions=${this.questions}
        .controls=${this.controls}
        env=${ifDefined(this.env)}
        apiKey=${ifDefined(this.apiKey)}
        ?disableTracking=${this.disableTracking}
        trackingSource=${ifDefined(this.trackingSource)}
        preload=${ifDefined(this.preload)}
        aspectRatio=${this.aspectRatio}
      ></vouch-embed-dialog-portal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'vouch-embed-dialog': DialogEmbed;
  }

  namespace JSX {
    interface IntrinsicElements {
      'vouch-embed-dialog': DialogEmbed;
    }
  }
}

export { DialogEmbed };
export type { DialogEmbedProps };
