function forwardEvent(type: string, fromElement: HTMLElement, toElement: HTMLElement) {
  function forwarder(event: Event) {
    toElement.dispatchEvent(new CustomEvent(event.type, event));
  }

  fromElement.addEventListener(type, forwarder);

  return () => {
    fromElement.removeEventListener(type, forwarder);
  };
}

export { forwardEvent };
