import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';

import type { DialogEmbedProps } from '.';

import '@a11y/focus-trap';

@customElement('vouch-embed-dialog-overlay')
class DialogOverlay extends LitElement {
  static styles = [
    css`
      :host {
        --vouch-media-player-border-radius: var(--vu-media-player-border-radius, 12px);
        --overlay-background-color: var(--vu-overlay-background-color, black);
        --overlay-background-opacity: var(--vu-overlay-background-opacity, 0.4);

        --dialog-width: var(--vu-dialog-width, 890px);
        --dialog-height: var(--vu-dialog-height, 500px);
      }

      .container {
        position: fixed;
        display: flex;
        inset: 0;
        opacity: 1;
        z-index: 2147483647;
        align-items: center;
        justify-content: center;
        transition: opacity 100ms ease-in;
      }

      .hidden {
        opacity: 0;
        pointer-events: none;
      }

      .background {
        position: absolute;
        inset: 0;
        opacity: var(--overlay-background-opacity);
        background-color: var(--overlay-background-color);
      }

      focus-trap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px;
        width: var(--dialog-width);
        height: var(--dialog-height);
        max-width: calc(100% - 80px);
        max-height: calc(100% - 80px);
      }

      .video-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;
      }

      .video-frame.grow {
        width: 100%;
        height: 100%;
      }

      vmp-icon-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
      }
    `
  ];

  @property({ type: Boolean }) open = false;
  @property({ type: Number }) aspectRatio: DialogEmbedProps['aspectRatio'] = 0;

  render() {
    return html`
      <div
        class=${classMap({
          container: true,
          hidden: !this.open
        })}
      >
        <div
          class="background"
          @click=${() => this.dispatchEvent(new CustomEvent('overlay:click', { bubbles: true, composed: true }))}
        ></div>
        <focus-trap>
          <div
            class=${classMap({
              'video-frame': true,
              grow: this.aspectRatio === 0
            })}
            style=${styleMap({
              aspectRatio: this.aspectRatio
            })}
          >
            <slot></slot>
            <vmp-icon-button
              icon="close"
              rounded="full"
              weight="heavy"
              @click=${() => this.dispatchEvent(new CustomEvent('close:click', { bubbles: true, composed: true }))}
            ></vmp-icon-button>
          </div>
        </focus-trap>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'vouch-embed-dialog-overlay': DialogOverlay;
  }

  namespace JSX {
    interface IntrinsicElements {
      'vouch-embed-dialog-overlay': DialogOverlay;
    }
  }
}

export { DialogOverlay };
