import { TEMPLATE_VERSION } from '@vouchfor/canvas-video';
import { v4 as uuidv4 } from 'uuid';

import type { TrackingPayload } from '.';
import type { Vouch } from '@vouchfor/video-utils';
import type { Environment } from '~/utils/env';

import packageJson from '~/../package.json';
import { getEnvUrls } from '~/utils/env';

function createVisitor(env: Environment) {
  const { publicApiUrl } = getEnvUrls(env);
  const visitorId = uuidv4();
  navigator.sendBeacon(`${publicApiUrl}/api/visitor`, JSON.stringify({ visitorId }));
  return visitorId;
}

function getUids(env: Environment) {
  if (typeof window === 'undefined') {
    return {
      client: null,
      tab: null,
      request: uuidv4()
    };
  }

  // Persisted for a user for the same device + browser, so we can e.g. search for all logs related to that browser
  let visitorId = window.localStorage?.getItem?.('vouch-uid-visitor');
  // Persisted for a user for the same device + browser, so we can e.g. search for all logs related to that browser
  let clientId = window.localStorage?.getItem?.('vouch-uid-client');
  // Persisted in session storage, so we can search for everything the user has done in a specific tab
  let tabId = window.sessionStorage?.getItem?.('vouch-uid-tab');
  // Not persisted, allows us to search for any logs related to a single FE request
  // E.g. BE should pass this request ID through all other services to be able to group logs
  const requestId = uuidv4();

  // Cache uids
  if (!visitorId) {
    visitorId = createVisitor(env);
    window.localStorage?.setItem?.('vouch-uid-visitor', visitorId);
  }

  if (!clientId) {
    clientId = uuidv4();
    window.localStorage?.setItem?.('vouch-uid-client', clientId);
  }

  if (!tabId) {
    tabId = uuidv4();
    window.sessionStorage?.setItem?.('vouch-uid-tab', tabId);
  }

  return {
    client: clientId,
    tab: tabId,
    request: requestId,
    visitor: visitorId
  };
}

function findVouchId(payload?: TrackingPayload, vouch?: Vouch) {
  if (payload && 'vouchId' in payload) {
    return payload.vouchId;
  }
  return vouch?.id ?? null;
}

function getReportingMetadata(source = 'embedded_player') {
  const [country, region] = Intl.DateTimeFormat().resolvedOptions().timeZone?.split?.('/') ?? [];

  const utmParams: any = {};
  [...new URLSearchParams(location.search).entries()].forEach(([key, value]) => {
    if (/utm/.test(key)) {
      const param = key.toLowerCase().replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());
      utmParams[param] = value;
    }
  });

  return {
    source,
    time: new Date(),
    region,
    country,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    referrer: document.referrer,
    currentUrl: location.href,
    embedType: 'media-player-embed',
    embedVersion: packageJson.version,
    templateVersion: TEMPLATE_VERSION,
    ...utmParams
  };
}

export { getUids, findVouchId, getReportingMetadata };
